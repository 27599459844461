@import './../abstract/mixins/grid.scss';
@import './../abstract/mixins/flex.scss';
@import './../abstract/mixins/breakpoints.scss';

.dtb-content-wrapper-with-sidebar {
  display: flex !important;
  flex: 1fr;
  width: 100%;
  min-height: 100%;
  height: 100%;
  padding: 0;

  @include breakpoint('xl', 'min') {
    flex: 1fr 280px;
  }

  .dtb-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    padding: 0px;
    flex-grow: 1;
    min-height: 0;
  }

  .dtb-sidebar {
    display: none;
    padding: 0px 0px 0px 0px;
  }

  @include breakpoint('xl', 'min') {
    .dtb-content {
      padding: 0px 0px 0px 0px;
    }

    .dtb-sidebar {
      display: flex;
      padding: 0px 0px 0px 14px !important;
    }
  }

  // ---------------
  // DETAIL HEADER CONTENT (RIGHT SIDE)
  // ---------------
  .dtb-detail-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    margin: 0px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0px;
    display: none;
    // enable header details top
    @media (orientation: portrait) {
      @include breakpoint('xl', 'min') {
        display: flex;
        margin-bottom: 14px;
      }
    }
    @media (orientation: landscape) {
      display: none;
      @include breakpoint('md', 'min') {
        display: flex;
        margin-bottom: 14px;
      }
    }

    .dtb-detail-header-split-item:first-child {
      width: 100%;
      display: flex;
      padding: 0;
      padding-left: 0px !important;
      .ant-card {
        width: 100%;
      }
    }

    .dtb-detail-header-split-item:last-child {
      display: none;
    }

    @include breakpoint('md', 'min') {
      .dtb-detail-header-split-item:first-child {
        display: flex;
        padding: 0px;
        width: 50%;
        box-sizing: border-box;
        padding-right: 7px !important;
        padding-left: 0px !important;

        .ant-card {
          width: 100%;
          margin: 0;
          box-sizing: border-box;
        }
      }

      .dtb-detail-header-split-item:last-child {
        display: block;
        .ant-card {
          width: 100%;
        }
      }

      // show card header split view
      .dtb-detail-header-split-item {
        width: 50%;
        display: flex;
        padding: 0;
        padding-left: 7px !important;

        .ant-card {
          width: 100%;
          margin: 0;
          margin-right: 0px; // reset left side
          box-sizing: border-box;
          padding: 0px 10px 10px 10px !important;
        }
      }
    }
  }

  // ---------------
  // SIDEBAR CONTENT (RIGHT SIDE)
  // ---------------
  .dtb-case-sidebar {
    width: 280px;
    height: 100%;
    overflow: hidden;
    padding: 0;
    //padding: 14px 0px 14px 0px;
    min-height: 0;

    .dtb-scroll-content {
      display: block;
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      .ant-card {
        width: 100%;
        margin: 0px 0px 14px 0px;
      }
    }

    .ant-card {
      width: 100%;
    }
  }
}
