@mixin grid($col, $row) {
  display: grid;
  grid-template-columns: $col;
  grid-template-rows: $row;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

@mixin grid-child($col, $row) {
  grid-column: $col;
  grid-row: $row;
  overflow: hidden;
}
